import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SlSocialGoogle } from "react-icons/sl";
import { LuTwitter } from "react-icons/lu";
import { TbBrandFacebook } from "react-icons/tb";
import Footer from "../Components/Footer";

function UserDashBoard() {
    // User's Name Initialization
    const [userName, setUserName] = useState("");

    // Logged In & Logged Out Indicator Initialization
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loginTime, setLoginTime] = useState("");
    const [logoutTime, setLogoutTime] = useState("");

    const [showSignIn, setShowSignIn] = useState(true);
    const [showSignUp, setShowSignUp] = useState(false);

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        userType: "owner/buyer",
    });

    // const [loginStatus, setLoginStatus] = useState("");
    // const [loginError, setLoginError] = useState("");

    const handleRegisterClick = () => {
        setShowSignIn(false);
        setShowSignUp(true);
    };

    const handleLoginClick = () => {
        setShowSignUp(false);
        setShowSignIn(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRegister = () => {
        // // Validate the data (implement your validation logic here)
        // if (!formData.first_name || !formData.last_name || !formData.email || !formData.password) {
        //   alert("Please fill in all required fields.");
        //   return;
        // }

        // Validate the data
        if (
            !formData.first_name ||
            !formData.last_name ||
            !formData.email ||
            !formData.phone ||
            !formData.password
        ) {
            alert("Please fill in all required fields.");
            return;
        }

        // More robust validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{11}$/;

        if (!emailRegex.test(formData.email)) {
            alert("Please enter a valid email address.");
            return;
        }

        if (!phoneRegex.test(formData.phone)) {
            alert("Please enter a valid 10-digit phone number.");
            return;
        }

        // Perform the fetch with the form data for the Sign Up 
        fetch("http://property.reworkstaging.name.ng/v1/users", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
        })
            .then((resp) => resp.json())
            .then((data) => {
                console.log(data);
                if (data.success) {
                    alert("Signup successful");
                    // Show the login form after successful registration
                    setShowSignIn(true);
                    setShowSignUp(false);

                } else {
                    if (data.type === "EXISTS") {
                        alert("Email or phone number already exists. Please log in or use a different email/phone.");
                    } else {
                        console.log(data);
                        alert("Error signing up. Please try again.");
                    }
                }

                // Update the userName state with the first name
                setUserName(formData.first_name);

                setFormData({
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone: "",
                    password: "",
                    // userType: "owner/buyer",
                });
            })
            .catch((err) => {
                console.error("Error fetching data:", err);
                alert("Error fetching data");
                setFormData({
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone: "",
                    password: "",
                    // userType: "owner/buyer",
                });
            });
    };

    const handleLogin = () => {
        fetch("http://property.reworkstaging.name.ng/v1/auth/login", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: formData.email,
                password: formData.password,
            }),
        })
            .then((resp) => resp.json())
            .then((data) => handleLoginResponse(data))
            .catch((err) => handleLoginError(err));
    };

    const handleLoginResponse = (data) => {
        console.log(data);
        if (data.type === "SUCCESS") {
            const firstName = data.data?.first_name || ''; // Use optional chaining
            const capitalizedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1); // Capitalize the first letter
            alert("Congratulations " + capitalizedFirstName + " ! You have successfully logged in.");
            // Set The Login P Tag Value
            const now = new Date();
            setIsLoggedIn(true);
            setLoginTime(now.toLocaleString());
            // Update the userName state with the retrieved user's first name
            setUserName(data.data.first_name.charAt(0).toUpperCase() + data.data.first_name.slice(1));
        } else {
            alert("It's either you have not signed up or the information you entered is incorrect. Kindly crosscheck your input and try again or try signing up.");
            // setLoginStatus("");
        }
    };

    // Handle The LogOut
    const handleLogout = () => {
        const now = new Date();
        setIsLoggedIn(false);
        setLogoutTime(now.toLocaleString());
        setUserName(""); // Clear user's name when logging out
    };

    const handleLoginError = (err) => {
        console.error("Error fetching data:", err);
        alert("Error fetching data");
        // setLoginStatus("");
    };



    return (
        <div className="UserDashboard">
            <div className="Container">
                <div className="Head">
                    <h2>{userName ? `Welcome to your Dashboard, ${userName}!` : "User's Dashboard"}</h2>
                    <span>
                        {isLoggedIn ? (
                            <p>🟢 Logged In: {loginTime}</p>
                        ) : (
                            <p>🔴 Logged Out</p>
                        )}
                        {isLoggedIn && logoutTime && (
                            <p>🔴 Logged Out: {logoutTime}</p>
                        )}
                    </span>
                    <div className="Logs">
                        {isLoggedIn && (
                            <button onClick={handleLogout}>
                                <p>Log Out</p>
                            </button>
                        )}
                    </div>
                </div>
                <div className="Authentication">
                    {showSignIn && (
                        <div className="SignIn">
                            <h4>Login</h4>
                            <input
                                type="email"
                                placeholder="Email*"
                                onChange={handleChange}
                                name="email"
                            />
                            <br />
                            <input
                                type="password"
                                placeholder="Password*"
                                onChange={handleChange}
                                name="password"
                            />
                            <button onClick={handleLogin}>
                                <p>Login</p>
                            </button>
                            <span>
                                <p>Not a member?</p>
                                <p onClick={handleRegisterClick}>Register here</p>
                            </span>
                            <div className="UserSocials">
                                <Link className="Socials" to="/">
                                    <SlSocialGoogle />
                                </Link>
                                <Link className="Socials" to="/">
                                    <LuTwitter />
                                </Link>
                                <Link className="Socials" to="/">
                                    <TbBrandFacebook />
                                </Link>
                            </div>
                        </div>
                    )}
                    {showSignUp && (
                        <div className="SignUp">
                            <h4>Register</h4>
                            <input
                                type="text"
                                name="first_name"
                                placeholder="First Name*"
                                value={formData.first_name}
                                onChange={handleChange}
                            />
                            <br />
                            <input
                                type="text"
                                name="last_name"
                                placeholder="Last Name*"
                                value={formData.last_name}
                                onChange={handleChange}
                            />
                            <br />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email*"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <br />
                            <input
                                type="phone"
                                name="phone"
                                placeholder="Phone*"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                            <br />
                            <input
                                type="password"
                                name="password"
                                placeholder="Password*"
                                value={formData.password}
                                onChange={handleChange}
                            />
                            <br />
                            <select
                                name="userType"
                                value={formData.userType}
                                onChange={handleChange}
                            >
                                <option value="owner/buyer">Owner/Buyer</option>
                                <option value="agency">Agency</option>
                            </select>
                            <p className="Instruction">
                                Your personal data will be used to support your <br />
                                experience throughout this website, to manage <br />
                                access to your account, and for other purposes <br />
                                described in our privacy policy.{" "}
                            </p>
                            <button onClick={handleRegister}>
                                <p>Register</p>
                            </button>
                            <span>
                                <p>Have an account? </p>
                                <p onClick={handleLoginClick}>Log In</p>
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <Footer className="Footer" />
        </div>
    );
}

export default UserDashBoard;