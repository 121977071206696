import React from "react";
import Navigation from "../Components/Navigation";
import Hero from "../Components/Hero";
import HeroContent from '../Components/HeroContents';
import Choices from "../Components/Choices";
import Spaces from "../Components/Spaces";
import Perfect from "../Components/Perfect";
import Discover from "../Components/Discover";
import Renovation from "../Components/Renovations";
import Loan from "../Components/Loan";
import NewsLetter from "../Components/NewsLetter";
import Blog from "../Components/Blog";
import Footer from "../Components/Footer";

function Home(){
    return(
        <div>
            <Navigation/>
            <Hero/>
            <HeroContent/>
            <Choices/>
            <Spaces/>
            <Perfect/>
            <Discover/>
            <Renovation/>
            <Loan/>
            <NewsLetter/>
            <Blog/>
            <Footer/>
        </div>
    );
}

export default Home;