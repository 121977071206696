import FindRealEstate from "./IMAGES/Find-Real-Estate.png";
import MeetRealtor from "./IMAGES/Meet-Relator.png";
import Documents from "./IMAGES/Documents.png";
import TakeTheKeys from "./IMAGES/Take-The-Keys.png";
function Perfect() {
  return (
    <div className="Perfect">
      <h2>
        How It Works? <br />
        <strong>Find a perfect home</strong>
      </h2>
      <div className="Container">
        <div className="col">
          <img src={FindRealEstate} alt="Find Real Estate" />
          <h3>Find real estate</h3>
          <p>
            Discover the perfect property with ease. Whether you're looking for
            a cozy apartment in the city, a spacious family home in the suburbs,
            or a luxurious penthouse, our extensive listings cater to every
            preference and budget.
          </p>
        </div>
        <div className="col">
          <img src={MeetRealtor} alt="Meet Relator" />
          <h3>Meet realtor</h3>
          <p>
            Connect with top-notch real estate professionals who are dedicated
            to making your property journey smooth and successful. We are
            committed to finding the perfect home or investment for you.
          </p>
        </div>
        <div className="col">
          <img src={Documents} alt="Documents" />
          <h3>Documents</h3>
          <p>
            Managing real estate transactions requires careful attention to
            detail and thorough documentation. Our team is here to ensure that
            every document is handled with precision and care.
          </p>
        </div>
        <div className="col">
          <img src={TakeTheKeys} alt="Take The Keys" />
          <h3>Take The Keys</h3>
          <p>
            Unlock the door to your new home with confidence and excitement.
            Taking possession of your property is a significant milestone, and
            we are here to ensure it's seamless and hassle-free.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Perfect;
