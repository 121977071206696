
function Blog() {
    return (
        <div className="Blog">
            <h1>
                Read our real <br />estate <strong>recent news</strong>
            </h1>
            <div className="Container">
                <div className="Card">
                    <img src="https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/blog-compact-img1.jpg" alt="Find A Good Agent" />
                    <span>
                        <p>PROPERTY</p>
                        -  By
                        <p>Julie Coleman</p>
                    </span>
                    <h4>How to find a Good </h4>
                    <h4>Real Estate Agent</h4>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. <br />
                        Inventore ullam quisquam asperiores hic culpa? Atque reprehenderit <br />
                        reiciendis aliquid porro doloribus obcaecati suscipit, adipisci, br
                        nihil ea, earum sint unde tempore eveniet!
                    </p>
                    <h6>Read More</h6>
                </div>

                <div className="Card">
                    <img src="https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/blog-compact-img2.jpg" alt="Find A Good Agent" />
                    <span>
                        <p>PROPERTY</p>
                        -  By
                        <p>Julie Coleman</p>
                    </span>
                    <h4>15 Ways to Negotiate </h4>
                    <h4>Your Lease Price</h4>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. <br />
                        Inventore ullam quisquam asperiores hic culpa? Atque reprehenderit <br />
                        reiciendis aliquid porro doloribus obcaecati suscipit, adipisci, br
                        nihil ea, earum sint unde tempore eveniet!
                    </p>
                    <h6>Read More</h6>
                </div>

                <div className="Card">
                    <img src="https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/blog-compact-img3.jpg" alt="Find A Good Agent" />
                    <span>
                        <p>PROPERTY</p>
                        -  By
                        <p>Julie Coleman</p>
                    </span>
                    <h4>Houses Designed by</h4>
                    <h4>Interior Experts</h4>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. <br />
                        Inventore ullam quisquam asperiores hic culpa? Atque reprehenderit <br />
                        reiciendis aliquid porro doloribus obcaecati suscipit, adipisci, br
                        nihil ea, earum sint unde tempore eveniet!
                    </p>
                    <h6>Read More</h6>
                </div>
            </div>
        </div>
    );
}

export default Blog;