import React, { useState } from "react";

function Discover() {
  const [status, setStatus] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);

    const isValid = form.checkValidity();
    if (!isValid) {
      setStatus("❌ Please fill all the fields correctly.");
      setTimeout(() => setStatus(""), 5000);
      return;
    }

    const response = await fetch("https://formspree.io/f/xnnajwna", {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.ok) {
      form.reset();
      setStatus("✅ Thank you for your enquiry! We will get back to you soon.");
      setTimeout(() => setStatus(""), 5000); // Clear status message after 5 seconds
    } else {
      setStatus("❌ Oops! There was a problem submitting your form. Please try again later or contact us directly.");
      setTimeout(() => setStatus(""), 5000); // Clear status message after 5 seconds
    }
  };

  return (
    <div className="Discover">
      <div className="Container">
        <div className="col">
          <h3>
            Discover a new <br />
            way of living
          </h3>
          <p>
            Embrace a lifestyle that caters to your every need and aspiration.
            Experience the perfect blend of comfort, convenience, and community
            in your new home.
          </p>
        </div>
        <div className="col">
          <h3>Make an enquiry</h3>
          <p>
            Save your time and easily rent or sell your property with <br />
            the lowest commission on the real estate market.
          </p>
          <form onSubmit={handleSubmit}>
            <input type="text" name="name" placeholder="Your name*" required />
            <br />
            <input type="email" name="email" placeholder="Your email*" required />
            <br />
            <input type="tel" name="phone" placeholder="Your phone number*" required />
            <br />
            <button type="submit">Make an enquiry</button>
          </form>
          {status && (
            <p
              style={{
                color: status.startsWith("✅") ? "green" : "red",
                marginTop: "10px",
              }}
            >
              {status}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Discover;