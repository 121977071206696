import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";

function BookTour() {
  return (
    <div className="BookTour">
      <div className="Container">
        <Navigation />
        <div className="Coming-Soon">
          <h5>Coming Soon!</h5>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default BookTour;