import React, { useEffect, useState } from "react";

const Home = () => {
  const images = [
    "https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/property-grid-img-7.jpg",
    "https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/property-grid-img-3.jpg",
    "https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/south-sun-house02.jpg",
    "https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/main-home-property.jpg",
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        height: "650px",
        backgroundColor: "transparent",
      }}
    >
      <div
        style={{
          display: "flex",
          transition: "transform 0.5s ease-in-out",
          transform: `translateX(-${currentSlide * (100 / images.length)}%)`,
          width: `${images.length * 100}%`,
          height: "100%",
          objectFit: "contain",
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              width: `${100 / images.length}%`,
              flex: "0 0 auto",
              objectFit: "contain",
            }}
            className={index === currentSlide ? "slide active" : "slide"}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        ))}
      </div>
      <div
        className="dot-container"
        style={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
        }}
      >
        {images.map((_, index) => (
          <div
            className="dot-controls"
            key={index}
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: index === currentSlide ? "#333" : "#bbb",
              margin: "0 5px",
              cursor: "pointer",
              transition: "background-color 0.3s ease-in-out",
            }}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Home;
