import FindRealEstate from "./IMAGES/Find-Real-Estate.png";
import Architect from "./IMAGES/Architect.png";
import InitiateRenovation from "./IMAGES/Renovation-Initialization.png";

function Renovation() {
  return (
    <div className="Renovation">
      <div className="Heading">
        <h3>
          Our experts will help you <br />
        </h3>
        <strong>make the renovation</strong>
      </div>
      <div className="Container">
        <div className="col">
          <div className="Table">
            <div className="TableLeft">
              <img src={FindRealEstate} alt="Find Inspiration" />
            </div>
            <div className="TableRight" id="TableRight">
              <h3>Find Inspiration</h3>
              <p>
                Discover new ideas and ignite your creativity with our curated
                collection of inspirational resources.
              </p>
            </div>
          </div>
          <div className="Table">
            <div className="TableLeft">
              <img src={Architect} alt="Find architect/designer" />
            </div>
            <div className="TableRight">
              <h3>Find architect/designer</h3>
              <p>
                Connect with top professionals to bring your vision to life and
                create your dream space.
              </p>
            </div>
          </div>
          <div className="Table">
            <div className="TableLeft">
              <img src={InitiateRenovation} alt="Begin renovation" />
            </div>
            <div className="TableRight">
              <h3>Find Inspiration</h3>
              <p>
                Explore endless possibilities to ignite your creativity and
                transform your living space.
              </p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="Actions">
            <div className="First">
              <img
                src="https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/main-home-img-4.jpg"
                alt="Searching For Experts"
              />
            </div>
            <div className="Second">
              <img
                src="https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/main-home-img-5.jpg"
                alt="Experts Renovating An Apartment"
              />
              <img
                src="https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/main-home-img-6.jpg"
                alt="Recently Renovated Apartment"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Renovation;
