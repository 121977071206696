import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from "./Pages/Home";
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import UserDashBoard from './Pages/UserDashboard';
import NotFound from './Components/404';
import Shop from "./Pages/Shop";
import BookTour from './Pages/BookTour';
import PropertyDetail from "./Pages/PropertyDetail";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/user_dashboard" element={<UserDashBoard/>} />
          <Route path="/properties" element={<Shop/>} />
          <Route path="/properties/:propertyName" element={<PropertyDetail />} /> {/* Route for property detail pages */}
          <Route path="/booktour" element={<BookTour/>} />
          {/* Fallback route for non-existent pages */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;