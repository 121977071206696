// import React from "react";

function Spaces() {
    return (
        <div className="Spaces">
            <div className="Container">
                <div className="col">
                    <div></div>
                    <img src="https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/main-home-img-1.jpg" alt="Couples Searching For A House Online" />
                </div>
                <div className="col">
                    <h4>Modern spaces and<br />
                        <strong>premium design</strong>
                    </h4>
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                         Voluptatibus voluptas reiciendis ab cum quaerat minus eligendi amet,
                          saepe quas est.
                    </p>
                    <div className="Services">
                        <p>- We Sell Houses</p>
                        <p>- We Rent Apartments</p>
                        <p>- We Sell Villas</p>
                        <button>Search Property</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Spaces;