import { Link } from "react-router-dom";

function Loan() {
  return (
    <div className="Loan">
      <div className="Container">
        <div className="col">
          <div></div>
          <img
            src="https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/main-home-img-2.jpg"
            alt="Loan Criteria"
          />
        </div>
        <div className="col">
          <h2>
            Explore your home <br />
            <strong>loan options</strong>
          </h2>
          <p>
            Discover tailored financing options that turn your home dreams into
            reality. From competitive rates to personalized advice, we make your
            home buying journey seamless and rewarding. Let’s find the ideal
            loan that fits your needs and helps you step into the home of your
            dreams.
          </p>
          <button>
            <Link to="/shop" className="Link"> Search Property </Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Loan;
