import React, { useState, useEffect } from "react";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";

function AboutUs() {
  const [medianSoldPrice, setMedianSoldPrice] = useState(0);
  const [propertiesSold, setPropertiesSold] = useState(0);
  const [medianRentPrice, setMedianRentPrice] = useState(0);
  const [propertiesRented, setPropertiesRented] = useState(0);
  const [propertiesForSale, setPropertiesForSale] = useState(0);

  useEffect(() => {
    // Function to simulate counting effect
    const countUp = (targetValue, setValue) => {
      let counter = 0;
      const interval = setInterval(() => {
        counter++;
        setValue(counter);
        if (counter >= targetValue) {
          clearInterval(interval);
        }
      }, 0.0001); // Adjust the interval timing to control the counting speed
    };

    // Call countUp function for each figure
    countUp(100, setMedianSoldPrice);
    countUp(25, setPropertiesSold);
    countUp(5100, setMedianRentPrice);
    countUp(40, setPropertiesRented);
    countUp(20, setPropertiesForSale);
  }, []);

  return (
    <div className="AboutUs">
      <Navigation />
      <div className="Container">
        <div className="Hero">
          <img
            src="https://wallpaperaccess.com/full/1218670.jpg"
            alt="About Us"
          />
          <h1>About Us</h1>
        </div>
        <div className="Banner">
          <div className="col">
            <img
              src="https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/about-us-img1.jpg"
              alt="New Homes Vision"
            />
          </div>
          <div className="col">
            <h4>The Inspirational CEO of URBAN VILLAS</h4>
            <p>
              Lateefah Busari is the trailblazing CEO of URBAN VILLAS, where her
              boundless passion for real estate and unwavering commitment to
              excellence shine brightly.
            </p>
            <p>
              With a career devoted to guiding individuals and families to their
              perfect homes, Lateefah has transformed the real estate landscape.
              Under her visionary leadership, URBAN VILLAS has blossomed,
              garnering acclaim for its bespoke service and unmatched expertise
              in the housing market.
            </p>
            <p>
              Lateefah's innovative strategies and steadfast dedication to
              customer satisfaction ensure that every client embarks on a
              remarkable journey to discover their dream home. With Lateefah at
              the helm, URBAN VILLAS doesn't just sell houses – it creates
              lifelong dreams and lasting memories.
            </p>

            {/* <button>View More </button> */}
          </div>
        </div>
        <div className="Statistics">
          <div className="Container">
            <div>
              <h3>N{medianSoldPrice.toLocaleString()}K</h3>
              <p>Median Sold Price</p>
            </div>
            <div>
              <h3>{propertiesSold}</h3>
              <p>Properties Sold</p>
            </div>
            <div>
              <h3>N{medianRentPrice.toLocaleString()}</h3>
              <p>Median Rent Price</p>
            </div>
            <div>
              <h3>{propertiesRented}</h3>
              <p>Properties Rented</p>
            </div>
            <div>
              <h3>{propertiesForSale}</h3>
              <p>Properties Available For Sale</p>
            </div>
          </div>
        </div>
        <div className="Mission">
          <div className="Container">
            <div className="col">
              <h3>Discover the Excellence of URBAN VILLAS</h3>
              <p>
                URBAN VILLAS is a premier real estate company dedicated to
                transforming the dream of homeownership into reality. Our
                mission is to provide bespoke solutions by expertly matching
                individuals and families with their perfect dream homes. With a
                focus on personalized service, we strive to understand the
                unique needs and desires of each client, ensuring a seamless and
                satisfying home-buying experience. At URBAN VILLAS, we believe
                everyone deserves to find their ideal living space, and we are
                committed to making that vision come true.
              </p>
              {/* <button>View More</button> */}
            </div>
            <div className="col">
              <img
                src="https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/about-us-img3.jpg"
                alt="New Homes Mission"
              />
            </div>
          </div>
        </div>
        <div className="Premium">
          {/* <img src="https://wallpaperaccess.com/full/1126812.jpg" alt="Premium Houses & Apartments" /> */}
          <img
            src="https://wallpaperaccess.com/full/2209906.jpg"
            alt="Premium Houses & Apartments"
          />
          <div className="Text">
            <h3>Premium Houses & Apartments </h3>
            <p>
              Experience the transformative power of Urban Villas.{" "}
              <br />
              Let us guide you on a journey where time and money are saved.{" "}
              <br />
              With every essential at your fingertips, discover the joy of
              finding your dream apartment. <br />
              Trust us to make your dreams a reality and create a place you can
              truly call home.{" "}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
