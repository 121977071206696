import { Link } from "react-router-dom";
import Navigation from "./Navigation";
import Footer from "./Footer";

function NotFound(){
    return(
        <div className="NotFound">
            <Navigation/>
            <div className="Container">
                <img src="https://wallpaperaccess.com/full/2210300.jpg" alt="Page Not Found!" />
                <div className="Message">
                    <h3>Oops... Page Not Found!</h3>
                    <p>The page you are looking for does not exist. It might have been moved or deleted</p>
                    <button><Link to="/" className="home">Back to Homepage</Link></button>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default NotFound;