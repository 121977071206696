import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { GiSettingsKnobs } from "react-icons/gi";
import { AiOutlineSearch } from "react-icons/ai";
import { LuSave } from "react-icons/lu";
import { GrPowerReset } from "react-icons/gr";

function HeroContent() {
    const [isCategoryOpen, setCategoryOpen] = useState(false);
    const [isLocationOpen, setLocationOpen] = useState(false);
    const [isAdvancedOpen, setAdvancedOpen] = useState(false);
    const [categoryValue, setCategoryValue] = useState("Category");
    const [locationValue, setLocationValue] = useState("Location");
    const [typeValue, setTypeValue] = useState("Type");
    const [apartmentValue, setApartmentValue] = useState("Apartment");
    const [modalLocationValue, setModalLocationValue] = useState("Location");

    // Modal Dropdowns
    const [isTypeOpen, setTypeOpen] = useState(false);
    const [isApartmentsOpen, setApartmentsOpen] = useState(false);
    const [isModalLocationOpen, setModalLocationOpen] = useState(false);

    // Progress Bar Declarations
    const [progress, setProgress] = useState(0); // Initial progress value (0-100)
    const minPrice = 0; // Minimum price
    const maxPrice = 650000; // Maximum price
    const price = ((maxPrice - minPrice) * progress) / 100; // Calculating price based on progress

    const handleProgressChange = (event) => {
        const newProgress = parseInt(event.target.value, 10);
        setProgress(newProgress);
    };

    const toggleCategoryDropdown = () => {
        setCategoryOpen(!isCategoryOpen);
    };

    const toggleLocationDropdown = () => {
        setLocationOpen(!isLocationOpen);
    };

    const handleCategorySelection = (selectedValue) => {
        setCategoryValue(selectedValue);
        setCategoryOpen(false);
    };

    const handleLocationSelection = (selectedValue) => {
        setLocationValue(selectedValue);
        setLocationOpen(false);
    };

    const toggleAdvancedModal = () => {
        setAdvancedOpen(!isAdvancedOpen);
    };

    const handleCancel = () => {
        setAdvancedOpen(false);
    };

    // Modal Contents Dropdown

    // Modal Type Handler Function
    const toggleTypeDropdown = () => {
        setTypeOpen(!isTypeOpen);
    };

    const handleTypeSelection = (selectedValue) => {
        setTypeValue(selectedValue);
        setTypeOpen(false);
    };

    // Modal Apartment Handler Function

    const toggleApartmentDropdown = () => {
        setApartmentsOpen(!isApartmentsOpen);
    };

    const handleApartmentSelection = (selectedValue) => {
        setApartmentValue(selectedValue);
        setApartmentsOpen(false);
    };

    // Modal Location Handler Function 

    const toggleModalLocation = () => {
        setModalLocationOpen(!isModalLocationOpen);
    };

    const handleModalLocationSelection = (selectedValue) => {
        setModalLocationValue(selectedValue);
        setModalLocationOpen(false);
    };

    return (
        <div className="HeroContent">
            <div className="Container">
                <h3>
                    Buy or rent properties
                    <br />
                    with no commission
                </h3>
                <div className="services">
                    <button>Buy</button>
                    <button>Rent</button>
                    <button>Sell</button>
                </div>
                <div className="Advanced-Bar">
                    <div onClick={toggleCategoryDropdown}>
                        {categoryValue}
                        <FiChevronDown />
                    </div>
                    <div onClick={toggleLocationDropdown}>
                        {locationValue}
                        <FiChevronDown />
                    </div>
                    <div onClick={toggleAdvancedModal}>
                        Advanced
                        <GiSettingsKnobs />
                    </div>
                    <div>
                        Search Property
                        <AiOutlineSearch />
                    </div>
                </div>
                {isCategoryOpen && (
                    <div className="dropdown">
                        <ul>
                            <li onClick={() => handleCategorySelection("Category")}>
                                Category
                            </li>
                            <li onClick={() => handleCategorySelection("Apartments")}>
                                Apartments
                            </li>
                            <li onClick={() => handleCategorySelection("Condos")}>
                                Condos
                            </li>
                            <li onClick={() => handleCategorySelection("Houses")}>
                                Houses
                            </li>
                            <li onClick={() => handleCategorySelection("Villas")}>
                                Villas
                            </li>
                        </ul>
                    </div>
                )}
                {isLocationOpen && (
                    <div className="dropdownLocation">
                        <ul>
                            <li onClick={() => handleLocationSelection("Location")}>
                                Location
                            </li>
                            <li onClick={() => handleLocationSelection("Brooklyn")}>
                                Brooklyn
                            </li>
                            <li onClick={() => handleLocationSelection("Manhattan")}>
                                Manhattan
                            </li>
                            <li onClick={() => handleLocationSelection("Queens")}>
                                Queens
                            </li>
                            <li onClick={() => handleLocationSelection("State Island")}>
                                State Island
                            </li>
                            <li onClick={() => handleLocationSelection("The Bronx")}>
                                The Bronx
                            </li>
                        </ul>
                    </div>
                )}
                {isAdvancedOpen && (
                    <div className="modal">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Advanced Filters</h4>
                                <button onClick={handleCancel}>X</button>
                            </div>
                            <div className="modal-details">
                                <h5>Property details:</h5>
                                <div className="Contents">
                                    <div onClick={toggleTypeDropdown}>
                                        {typeValue}
                                        <FiChevronDown />
                                    </div>
                                    <div onClick={toggleApartmentDropdown}>
                                        {apartmentValue}
                                        <FiChevronDown />
                                    </div>
                                    <div onClick={toggleModalLocation}>
                                        {modalLocationValue}
                                        <FiChevronDown />
                                    </div>
                                </div>
                                <div className="Second-Content">
                                    <h5>Property size:</h5>
                                    <div className="Container">
                                        <div className="First">
                                            <div>
                                                <input type="text" placeholder="Min area m2" />
                                            </div>
                                            <div>
                                                <input type="text" placeholder="Max area m2" />
                                            </div>
                                        </div>
                                        <div className="Second">
                                            <div>
                                                <p>Bedroom:</p>
                                                <input type="text" />
                                            </div>
                                            <div>
                                                <p>Bathroom:</p>
                                                <input type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ProgressContainer">
                                        <div className="progress-bar">
                                            <input
                                                type="range"
                                                min="0"
                                                max="100"
                                                value={progress}
                                                onChange={handleProgressChange}
                                                style={{ backgroundColor: 'red' }}
                                            />
                                            <div className="price">From: $0 To: ${price}</div>
                                            <div className="progress-indicator" style={{ width: `${progress}%`, backgroundColor: 'red' }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Amenities">
                                    <div className="Container">
                                        <div className="col">
                                            <div>
                                                <input type="checkbox" />
                                                <p>Air Condition</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Disabled Access </p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Floor</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Heating</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Renovation</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Window Type</p>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div>
                                                <input type="checkbox" />
                                                <p>Cable TV</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Elevator</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Furnishing</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Intercom</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Security</p>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div>
                                                <input type="checkbox" />
                                                <p>Ceiling Height</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Fence</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Garage</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Parking</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Swimming Pool</p>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div>
                                                <input type="checkbox" />
                                                <p>Construction Year</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Fireplace</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Garden</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>Pet Friendly</p>
                                            </div>
                                            <div>
                                                <input type="checkbox" />
                                                <p>WiFi</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="Button-Container">
                                        <div className="col">
                                            <button>
                                                <AiOutlineSearch/> 
                                                <p>Search Property</p>
                                            </button>
                                        </div>
                                        <div className="col">
                                            <button>
                                            <LuSave/>
                                            <p>Save</p>
                                            </button>
                                            <button>
                                            <GrPowerReset/>
                                            <p>Reset</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {isTypeOpen && (
                                    <div className="Type-Dropdown">
                                        <ul>
                                            <li onClick={() => handleTypeSelection("Type")}>
                                                Type
                                            </li>
                                            <li onClick={() => handleTypeSelection("Buy")}>
                                                Buy
                                            </li>
                                            <li onClick={() => handleTypeSelection("For Rent")}>
                                                For Rent
                                            </li>
                                            <li onClick={() => handleTypeSelection("Sell")}>
                                                Sell
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                {isApartmentsOpen && (
                                    <div className="Apartment-Dropdown">
                                        <ul>
                                            <li onClick={() => handleApartmentSelection("Category")}>
                                                Category
                                            </li>
                                            <li onClick={() => handleApartmentSelection("Apartments")}>
                                                Apartments
                                            </li>
                                            <li onClick={() => handleApartmentSelection("Condos")}>
                                                Condos
                                            </li>
                                            <li onClick={() => handleApartmentSelection("Houses")}>
                                                Houses
                                            </li>
                                            <li onClick={() => handleApartmentSelection("Villas")}>
                                                Villas
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                {isModalLocationOpen && (
                                    <div className="Location-Dropdown">
                                        <ul>
                                            <li onClick={() => handleModalLocationSelection("Location")}>
                                                Location
                                            </li>
                                            <li onClick={() => handleModalLocationSelection("Brooklyn")}>
                                                Brooklyn
                                            </li>
                                            <li onClick={() => handleModalLocationSelection("Manhattan")}>
                                                Manhattan
                                            </li>
                                            <li onClick={() => handleModalLocationSelection("Queens")}>
                                                Queens
                                            </li>
                                            <li onClick={() => handleModalLocationSelection("State Island")}>
                                                State Island
                                            </li>
                                            <li onClick={() => handleModalLocationSelection("The Bronx")}>
                                                The Bronx
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default HeroContent;
