import { useState } from "react";
import { Link } from "react-router-dom";
import { BsGeoAlt } from "react-icons/bs";
import { BsBuilding } from "react-icons/bs";
import { FaCar } from "react-icons/fa";
// import { TbResize } from "react-icons/tb";
// import { LuBedDouble } from "react-icons/lu";
// import { LuBath } from "react-icons/lu";
// import { FaStar } from "react-icons/fa";
// import { FaTimes } from "react-icons/fa";
// import { SlSocialGoogle } from "react-icons/sl";
// import { LuTwitter } from "react-icons/lu";
// import { TbBrandFacebook } from "react-icons/tb";
// import Modal from "react-modal";
import properties from "../Data/PropertiesData"; // Importing the properties array

function Choices() {

  const [isHovered] = useState(false);
  // The Star Functions Declarations
  // const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };

  // // The Star Functions 
  // const handleImageMouseEnter = (index) => {
  //   setHoveredCardIndex(index);
  // }

  // const handleImageMouseLeave = () => {
  //   setHoveredCardIndex(null);
  // }

  // // Login Modal Functions
  // const handleModalOpen = () => {
  //   setIsModalOpen(true);
  // };

  // const handleModalClose = () => {
  //   setIsModalOpen(false);
  // };

  // // Register Modal Functions 
  // const handleSecondModalOpen = () => {
  //   setIsModalOpen(false);
  //   setIsSecondModalOpen(true);
  // };

  // const handleSecondModalClose = () => {
  //   setIsSecondModalOpen(false);
  // };

  // const handleLoginModalOpen = () => {
  //   setTimeout(() => {
  //     setIsSecondModalOpen(false);
  //     setIsModalOpen(true);
  //   }, 100); // Adjust the delay as needed
  // };

  return (
    <div className="Choices">
      <h4>
        Our choice of <br />
        popular real estate
      </h4>
      <div className="Container">
            {properties.map((property, index) => (
              <div className="Card" key={index}>
                <Link to={`/properties/${property.url}`}>
                  <img
                    src={property.images[0]} // Display the first image by default
                    alt={property.name}
                  />
                </Link>
                <div className="Location">
                  <BsGeoAlt />
                  <p>
                    <span>{property.conciseLocation}</span>
                  </p>
                </div>
                <Link
                  to={`/properties/${property.url}`}
                  style={{
                    textDecoration: "none", // Removes underline
                    color: "black", // Sets text color to black
                  }}
                >
                  <div
                    className={`Name ${isHovered ? "Underline" : ""}`}
                    // onMouseEnter={handleMouseEnter}
                    // onMouseLeave={handleMouseLeave}
                  >
                    {property.name}
                  </div>
                </Link>
                <div className="Description">{property.description}</div>
                <hr />
                <div className="Main">
                  <h3 className="Price">{property.price}</h3>
                  <div className="Contents">
                    <div>
                      <BsBuilding />
                      <p>{property.floors}</p>
                    </div>
                    <div>
                    <FaCar />
                      <p>{property.parkingLot}</p>
                    </div>
                    {/* <div>
                      <LuBath />
                      <p>{property.baths}</p>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
      <div className="Browse">
        <button><Link to="/shop" className="Text">Browse More Properties</Link></button>
      </div>
    </div>
  );
}

export default Choices;