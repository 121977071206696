import React, { useEffect, useState } from "react";
import darkModeFooter from "./IMAGES/URBAN-VILLAS.png";
import lightModeFooterLogo from "./IMAGES/URBAN-VILLAS.png";

function Footer() {
    const footerYear = new Date().getFullYear();
    const [logoSrc, setLogoSrc] = useState(lightModeFooterLogo);

    useEffect(() => {
        const darkModeListener = (e) => {
            setLogoSrc(e.matches ? darkModeFooter : lightModeFooterLogo);
        };

        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        darkModeMediaQuery.addListener(darkModeListener);

        return () => {
            darkModeMediaQuery.removeListener(darkModeListener);
        };
    }, []);

    return (
        <div className="Footer">
            <div className="Container">
                <div className="col">
                    <img src={logoSrc} alt="New Homes" />
                    <p>Unlock the power of real estate with our dynamic online platform. <br />
                        Buy, sell, and rent apartments effortlessly through our user-friendly website. <br />
                        Discover the perfect home, connect with trusted agents, and experience seamless transactions. <br />
                        Your dream apartment is just a few clicks away. <br />
                        Join us today and embark on your real estate journey with confidence.
                    </p>
                </div>
                <div className="col">
                    <div className="First">
                        <h5>Contact Us</h5>
                        <li>Lagos, Nigeria</li>
                        <li>+2348168907617</li>
                        <li>info@urbanvillas.com.ng</li>
                    </div>
                    <div className="Second">
                        <h5>Categories</h5>
                        <li>Recent property</li>
                        <li>To Sell</li>
                        <li>To Buy</li>
                        <li>To Rent</li>
                    </div>
                    <div className="Third">
                        <h5>Links</h5>
                        <li>About Us</li>
                        <li>Contact Us</li>
                        <li>Get In Touch</li>
                    </div>
                </div>
            </div>
            <hr />
            <div className="Bottom">
                <div className="Left">
                    <p>© <span> {footerYear}</span> <li>Urban Villas </li>, <ins></ins> All Rights Reserved</p>
                </div>
                <div className="Right">
                    <p>Follow Us:</p>
                    <li>Instagram</li>
                    <li>Facebook</li>
                    <li>Youtube</li>
                    <li>Twitter</li>
                </div>
            </div>
        </div>
    );
}

export default Footer;