// Paramount Towers Images
import ParamountTowers from "../Assets/IMAGES/Paramount-Towers/ParamountTowers.JPG";
import ParamountTowersBedroom from "../Assets/IMAGES/Paramount-Towers/ParamountTowersBedroom.JPG";
import ParamountTowersGym from "../Assets/IMAGES/Paramount-Towers/ParamountTowersGym.JPG";
import ParamountTowersKitchen from "../Assets/IMAGES/Paramount-Towers/ParamountTowersKitchen.JPG";
import ParamountTowersLobby from "../Assets/IMAGES/Paramount-Towers/ParamountTowersLobby.JPG";
import ParamountTowersReasons from "../Assets/IMAGES/Paramount-Towers/ParamountTowersReasons.JPG";
import ParamountTowersClip from "../Assets/Videos/PARAMOUNT-TOWERS.mp4";

// Rockhampton Images
import RockHampton from "../Assets/IMAGES/RockHampton/RockHampton.jpeg";
import RockHampton2 from "../Assets/IMAGES/RockHampton/RockHampton2.jpeg";
import RockHampton3 from "../Assets/IMAGES/RockHampton/RockHampton3.jpeg";
import RockHampton4 from "../Assets/IMAGES/RockHampton/RockHampton4.jpeg";
import RockHampton5 from "../Assets/IMAGES/RockHampton/RockHampton5.jpeg";
import RockHamptonClip from "../Assets/Videos/ROCK-HAMPTON.mp4";


// SkyVilla Images
import SkyVilla from "../Assets/IMAGES/SkyVilla/SkyVilla.jpeg";
import SkyVilla2 from "../Assets/IMAGES/SkyVilla/Skyvilla2.jpeg";
import SkyVilla3 from "../Assets/IMAGES/SkyVilla/Skywilla3.jpeg";
import SkyVilla4 from "../Assets/IMAGES/SkyVilla/SkyVilla4.jpeg";
import SkyVilla5 from "../Assets/IMAGES/SkyVilla/SkyVilla5.jpeg";
import SkyVilla6 from "../Assets/IMAGES/SkyVilla/SkyVilla6.jpeg";
import SkyVilla7 from "../Assets/IMAGES/SkyVilla/SkyVilla7.jpeg";


// Banana Island Images
import Banana from "../Assets/IMAGES/Banana-Island/BananaIsland.jpeg";
import Banana2 from "../Assets/IMAGES/Banana-Island/BananaIsland2.jpeg";
import Banana3 from "../Assets/IMAGES/Banana-Island/BananaIsland3.jpeg";
import Banana4 from "../Assets/IMAGES/Banana-Island/BananaIsland4.jpeg";
import Banana5 from "../Assets/IMAGES/Banana-Island/BananaIsland5.jpeg";

const properties = [
  {
    name: "Paramount Twin Towers",
    // developers: "Grenadines Homes",
    conciseLocation: "Lekki, Lagos Nigeria",
    location: "Remi Olowude Street, Okunde Bluewaters Scheme, Lekki, Lagos.",
    description:
      "A Contemporary designed two blocks of 13-floor residential tower with each unit having a large terrace frontage, excellent ocean/city views and top-of-the-line amenities.The first 3 floors elevated for car park spaces and the remaining 10 floors are residential units.",
    features: "- Parking Lot.",
    price: "₦190,000,000",
    floors: "13 Floors",
    parkingLot: "3 Floors",
    video: ParamountTowersClip,
    Details: [
      "- Studio Apartment: Sold Out",
      "- 1 Bedroom Apartment: Sold Out",
      "- 2 Bedroom Apartment: 18 units available",
      "- 3 Bedroom Apartment: 27 units available",
      "- 3 Bedroom Maisonette: 1 unit available",
      "- Developers : Grenadines Homes",
    ],
    images: [
      ParamountTowers,
      ParamountTowersBedroom,
      ParamountTowersGym,
      ParamountTowersKitchen,
      ParamountTowersLobby,
      ParamountTowersReasons,
    ],
    url: "paramount-twin-towers", // Updated to match the URL segment
  },
  {
    name: "Rockhampton Residences",
    conciseLocation: "Katampe Extension, Abuja Nigeria",
    location:
      "Situated in Katampe Extension in Abuja, with easy access to town, proximity to major landmarks and other recreational facilities like the IBB Golf Course.",
    description:
      "Introducing, Rockhampton Residences, located in Katampe Extension, in the heart of Abuja. Rockhampton is a project of Palton Morgan Holdings by Grenadines Homes - a blend of nature, innovation, and functionality, put together to make an exquisite mountainside community.",
    features: "- Blend Of Nature",
    price: "₦170,000,000",
    floors: "30",
    parkingLot: "5 Floors",
    video: RockHamptonClip,
    Details: [
      "- 4 Bedroom Pent Apartments - 459m2 (Sold Out)",
      "- 3 Bedroom Luxury Condos + 1 BQ- 189m2 ( 7 units available)",
      "- 4 Bedroom Terrace Apartments + 1 BQ - 249m2 (11 units available)",
      "- 4 Bedroom Semi Detached Luxury Villas + 1 BQ- 284m2 (8 units available)",
    ],
    images: [
      RockHampton,
      RockHampton2,
      RockHampton3,
      RockHampton4,
      RockHampton5,
    ],
    url: "rockhampton-residencies", // Updated to match the URL segment
  },
  {
    name: "Skyvilla ",
    conciseLocation: "Probyn Road, Ikoyi Lagos, Nigeria",
    location: "Probyn Road, off Bourdillon Road, Ikoyi.",
    description:
      "This is an update on our ongoing project named SKYVILLA by Grenadines. We are at 70% completion, currently on the 9th Floor with BCL  Construction & Engineering working on site nonstop. It is a 10floor project with each apartment sitting on 3 floor, which gives an optional Panoramic elevator in each unit. We have One (1) unit left out of 24 units as investors are showing intent.",
    price: "₦1,500,000,000",
    features: "Panoramic elevator in each unit.",
    floors: "10 Floors",
    parkingLot: "1 Floor",
    Details: [
      "- 4 Bedroom Condo",
      "- Family Living & Maid's Room",
      "- By Grenadines Homes",
    ],
    images: [
      SkyVilla,
      SkyVilla2,
      SkyVilla3,
      SkyVilla4,
      SkyVilla5,
      SkyVilla6,
      SkyVilla7,
    ],
    url: "skyvilla", // Updated to match the URL segment
  },
  {
    name: "L5 Banana - Banana Island",
    conciseLocation: "Banana Island, Ikoyi, Lagos",
    location: "Banana Island Foreshore Estate, Ikoyi, Lagos.",
    description: "L5 Banana by Grenadines Homes is a signature address within the prestigious Banana Island neighbourhood, designed for the upper class looking for out-of-the-ordinary homes that beats the conventional and an opportunity to reward themselves.",
    price: "₦300,000,000",
    features: [
        "- Jetty",
        "- Infinity Pool",
        "- Waterfront Living",
        "- Ample car park spaces (3 car park spaces for each unit)",
        "- Business Suite",
        "- State-of-the-art Gymnasium",
        "- Squash Court",
        "- 24 Hours Security Services + CCTV",
        "- 24 Hours Electricity",
        "- Smart Home Technology",
        "- Water Treatment Plant",
        "- Waste Treatment Plant",
    ],
    floors: "10 Floors",
    parkingLot: "1 Floor",
    Details: [
      "- 2 Bedroom Apartments: 8 Units Available",
      "- 4 Bedroom Apartments: 5 units available",
      "- 4 Bedroom Maisonette:  6 units available",
      "- 4 Bedroom Penthouses: 1 unit available",
      "- Contractors: Not Yet Confirmed.",
    ],
    images: [
      Banana,
      Banana2,
      Banana3,
      Banana4,
      Banana5,
      // ParamountTowersReasons,
    ],
    url: "l5-banana-island",
  },
];

export default properties;
