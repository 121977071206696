import React, { useState } from "react";
import Navigation from "../Components/Navigation";
import Contact_Us from "../Components/IMAGES/Contact-Us.jpg";
import Footer from "../Components/Footer";

function ContactUs() {
  const [status, setStatus] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);

    const isValid = form.checkValidity();
    if (!isValid) {
      setStatus("Please fill all the fields correctly.");
      return;
    }

    const response = await fetch("https://formspree.io/f/xgvwodep", {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.ok) {
        form.reset();
        setStatus("✅ Thank you for reaching out! Your message has been successfully sent, and we will get back to you promptly.");
        setTimeout(() => setStatus(""), 5000);  // Clear status message after 5 seconds
      } else {
        setStatus("❌ Oops! There was a problem submitting your form. Please try again later or contact us directly.");
        setTimeout(() => setStatus(""), 5000);  // Clear status message after 5 seconds
      }
  };

  return (
    <div className="ContactUs">
      <Navigation />
      <div className="Container">
        <div className="Hero">
          <img src={Contact_Us} alt="Contact Us" />
          <h3>Contact Us</h3>
        </div>
        <div className="Message">
          <div className="Container">
            <div className="col">
              <h3>Send Us A Message</h3>
              <form onSubmit={handleSubmit}>
                <input type="text" name="name" placeholder="Your Name*" required /> <br />
                <input type="email" name="email" placeholder="Your Email*" required /> <br />
                <textarea name="message" cols="30" rows="10" placeholder="Message*" required></textarea> <br />
                <button type="submit">Send A Message</button>
              </form>
              {status && (
                <p
                  style={{
                    color: status.startsWith("✅") ? "green" : "red",
                    marginTop: "10px",
                  }}
                >
                  {status}
                </p>
              )}
            </div>
            <div className="col">
              <h3>Contact Us</h3>
              <div className="Contents">
                <div className="Content1">
                  <h5>Main Office</h5>
                  <li>18A Olu Holloway, Ikoyi Lagos Nigeria</li>
                  <li>+2348168907617</li>
                  <li>info@urbanvillas.com.ng</li>
                  <div>
                    <p>Monday – Friday 09:00 – 20:30</p>
                    <p>Saturday 09:00 – 18:00</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="More">
          <div className="Container">
            <div className="col">
              <h3>Sign to newsletter</h3>
              <p>
                Save your time and easily rent or sell your property with the <br />
                lowest commission on the real estate market.
              </p>
              <div>
                <input type="email" placeholder="Your email" />
                <button>Sign Up</button>
              </div>
            </div>
            <div className="col">
              <h3>Become a Real Estate Agent</h3>
              <p>
                Join us now and become a part of our elite team of real estate agents. <br />
                Experience the thrill of success and unlock your true potential. <br />
                With unparalleled support and lucrative opportunities, we will guide you towards a rewarding career in the dynamic world of real estate. <br />
                Don't miss out on this chance to excel. Start your journey with us today!
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
