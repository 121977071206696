

function NewsLetter(){
    return(
        <div className="NewsLetter">
            <img src="https://newhome.qodeinteractive.com/wp-content/uploads/2023/03/main-home-img-7.jpg" alt="Sign Up For Our Newsletter" />
            <div className="Container">
                <div className="col">
                    <h3>
                    Find a home that <br />
                    truly suits you
                    </h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt, perferendis.</p>
                </div>
                <div className="col">
                    <h5>Sign in to newsletter </h5>
                    <p>
                     Save your time and easily rent or sell your property with <br />
                     the lowest commission on the real estate market.
                    </p>
                    <div>
                        <input type="email" placeholder="Your email"/>
                        <button>Sign up</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsLetter;