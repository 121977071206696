import { Link, useParams } from "react-router-dom";
import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import properties from "../Data/PropertiesData"; // Adjust the import path as needed
import Navigation from "../Components/Navigation";
import { MdChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";

function PropertyDetail() {
  const { propertyName } = useParams();

  // Play The Video Automatically When The User Reaches That Section
  const videoRef = useRef(null);

  useEffect(() => {
    const currentVideoRef = videoRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            currentVideoRef.play();
          } else {
            currentVideoRef.pause();
          }
        });
      },
      { threshold: 0.5 } // Adjust this threshold as needed
    );

    if (currentVideoRef) {
      observer.observe(currentVideoRef);
    }

    return () => {
      if (currentVideoRef) {
        observer.unobserve(currentVideoRef);
      }
    };
  }, []);

  // Find the property based on the URL parameter
  const property = properties.find((prop) => prop.url === propertyName);

  if (!property) {
    return <div>Property not found</div>; // Handle case where property is not found
  }

  const settings = {
    customPaging: function (i) {
      return (
        <div>
          <img
            src={property.images[i]}
            alt={`Thumbnail ${i + 1}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "fallback-image-url";
            }}
            style={{
              width: "20px",
              height: "20px",
            }}
          />
        </div>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="PropertyDetail">
      <Navigation />
      <div className="Container">
        <div className="Information">
          <h1>
            <Link to="/properties" className="Back-Link">
              {property.name}
            </Link>
          </h1>
          <h1>Price: {property.price}</h1>
        </div>
        <div className="Carousel">
          <div className="Carousel-Wrapper">
            <Slider {...settings}>
              {property.images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={`${property.name} Slide ${index + 1}`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "fallback-image-url";
                    }}
                    // style={{
                    //   objectFit: "cover"
                    // }}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="Description">
          <h1>Description</h1>
          <p>{property.description}</p>
        </div>
        <div className="Features">
          <h1>Property Features</h1>
          <p>{property.features}</p>
          <div className="Details">
            <h1>Property Details</h1>
            <div className="Col">
              {property.Details.map((detail, index) => (
                <p key={index}>{detail}</p>
              ))}
            </div>
          </div>
        </div>
        <div className="Videos">
          <h1>Video</h1>
          {property.video ? (
            <video className="video-player" controls autoPlay ref={videoRef}>
              <source src={property.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <p>Video coming soon!</p>
          )}
        </div>
        <div className="Location">
          <h1>Location</h1>
          <p>{property.location}</p>
        </div>
        <div className="Inquiries">
          <h1>Want To Make More Inquiries?</h1>
          <Link to="/contact_us" className="Button">
            <button>Contact Us!</button>
          </Link>
        </div>
        {/* <p>{property.description}</p>
        <p>Price: {property.price}</p>
        <p>Size: {property.size}</p>
        <p>Beds: {property.beds}</p>
        <p>Baths: {property.baths}</p> */}
        {/* Add more details and styling as needed */}
      </div>
      <Footer />
    </div>
  );
}

// Custom arrow components for slick carousel
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <MdOutlineChevronRight
      className={className}
      style={{ ...style, display: "block", color: "black", fontSize: "30px" }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <MdChevronLeft
      className={className}
      style={{ ...style, display: "block", color: "black", fontSize: "30px" }}
      onClick={onClick}
    />
  );
};

export default PropertyDetail;
