import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./IMAGES/URBAN-VILLAS.png";
import { BsFillPersonFill } from "react-icons/bs";
// import { GrAdd } from "react-icons/gr";
import { BsQuestionLg } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { SlSocialGoogle } from "react-icons/sl";
import { LuTwitter } from "react-icons/lu";
import { TbBrandFacebook } from "react-icons/tb";
// import { FaBars, FaTimes } from "react-icons/fa";
import { FaBars } from "react-icons/fa6";
import Modal from "react-modal";

const Navigation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Login Modal Functions
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  // Register Modal Functions
  const handleSecondModalOpen = () => {
    setIsModalOpen(false);
    setIsSecondModalOpen(true);
  };

  const handleSecondModalClose = () => {
    setIsSecondModalOpen(false);
  };

  const handleLoginModalOpen = () => {
    setTimeout(() => {
      setIsSecondModalOpen(false);
      setIsModalOpen(true);
    }, 100); // Adjust the delay as needed
  };

  // Menu toggle function
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="Navigation">
      <div className="Container">
        <div className="col">
          <img src={Logo} className="Logo" alt="Logo" />
        </div>
        <div className="col">
          {/* <div className="col">
                    <img src={Logo} alt="Logo" />
                </div> */}
          <p>
            <Link to="/" className="Link">
              HOME
            </Link>
          </p>
          <p>
            <Link to="/properties" className="Link">
              PROPERTIES
            </Link>
          </p>
          <p>
            <Link to="/booktour" className="Link">
              BOOK TOUR
            </Link>
          </p>
          <p>
            <Link to="/contact_us" className="Link">
              CONTACT US
            </Link>
          </p>
        </div>
        <div className="col">
          <div>
            <BsFillPersonFill />
            <p>
              <Link to="" className="Links" onClick={handleModalOpen}>
                JOIN US
              </Link>
            </p>
          </div>
          {/* <div>
                        <GrAdd />
                        <p>
                            <Link to="/user_dashboard" className="Links">
                                ADD PROPERTY
                            </Link>
                        </p>
                    </div> */}
          <div>
            <BsQuestionLg />
            <p>
              <Link to="/about_us" className="Links">
                ABOUT US
              </Link>
            </p>
          </div>
        </div>
        <div className="col">
          <img src={Logo} className="Logo" alt="Logo" />
        </div>
        <div className="MenuIcon" onClick={handleMenuToggle}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
        {isMenuOpen && (
          <div className="Navigation-Menu">
            <div>
              <Link to="/" className="Link">
                HOME
              </Link>
            </div>
            <div>
              <Link to="/properties" className="Link">
                PROPERTIES
              </Link>
            </div>
            <div>
              <Link to="/booktour" className="Link">
                BOOK TOUR
              </Link>
            </div>
            <div>
              <Link to="/contact_us" className="Link">
                CONTACT US
              </Link>
            </div>
            {/* <div>
              <Link to="" className="Link" onClick={handleModalOpen}>
                JOIN US
              </Link>
            </div> */}
            <div>
                <Link to="/about_us" className="Link">
                  ABOUT US
                </Link>
              </div>
          </div>
        )}
      </div>
      <Modal
        className="NavigationModal"
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
          content: {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "15px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
            zIndex: "9999",
            width: "450px",
            height: "520px",
          },
        }}
      >
        <div className="Head">
          <span></span>
          <h2>Login</h2>
          <FaTimes className="delete" onClick={handleModalClose} />
        </div>
        <div className="Content">
          <input type="username" placeholder="User Name*" />
          <br />
          <input type="password" placeholder="Password*" />
          <div className="Solution">
            <p>Remember me</p>
            <p>Lost Your Password</p>
          </div>
          <button>Login</button>
          <span>
            <p>Not a member?</p>
            <p>
              <Link className="WantReg" to="" onClick={handleSecondModalOpen}>
                Register here
              </Link>
            </p>
          </span>
          <div className="ModalSocials">
            <Link className="Socials" to="/">
              <SlSocialGoogle />
            </Link>
            <Link className="Socials" to="/">
              <LuTwitter />
            </Link>
            <Link className="Socials" to="/">
              <TbBrandFacebook />
            </Link>
          </div>
        </div>
      </Modal>

      {/* Register Modal  */}
      <Modal
        className="NavigationModal"
        isOpen={isSecondModalOpen}
        onRequestClose={handleSecondModalClose}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
          content: {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "15px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
            zIndex: "9999",
            width: "450px",
            height: "760px",
          },
        }}
      >
        <div className="Head">
          <span></span>
          <h2>Register</h2>
          <FaTimes className="delete" onClick={handleSecondModalClose} />
        </div>
        <div className="Content2">
          {/* Second modal content */}
          <input type="username" placeholder="User Name*" /> <br />
          <input type="email" placeholder="Email*" /> <br />
          <input type="password" placeholder="Password*" /> <br />
          <input type="password" placeholder="Repeat Password*" /> <br />
          <select name="" id="">
            <option value="Owner/Buyer">Owner/Buyer</option>
            <option value="Agency">Agency</option>
          </select>
          <p>
            Your personal data will be used to support your <br />
            experience through this website, to manage <br />
            access to your account, and for other purposes <br />
            described in our privacy policy.
          </p>
          <button>Register</button>
          <span>
            <p>Have an account?</p>
            <p>
              <Link className="WantLog" to="" onClick={handleLoginModalOpen}>
                Log in
              </Link>
            </p>
          </span>
        </div>
      </Modal>
    </div>
  );
};

export default Navigation;
